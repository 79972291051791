<template>
  <div>
    <v-autocomplete color="primary"
                    :ref="refer"
                    item-color="primary" clearable
                    :items="items"
                    :label="label"
                    :item-text="text" multiple
                    :item-value="value"
                    v-model="selectedDatas"
                    :rules="rules"
                    @mouseup="checkState"
                    @change="$emit('data:change', selectedDatas)"
                    @update:search-input="$emit('data:search', $event)"
                    :small="small_prop"
                    :flat="flat_prop"
                    :solo="solo_prop"
                    :loading="loading"
                    :background-color="backgroundColor"
                    :disabled="disabled_prop"
                    :return-object="returnObject"
                    persistent-hint
                    small
                    outlined
                    dense
                    hide-details
                    @click:clear="$emit('data:change', [])"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip small v-if="index === 0">
          <span>{{ item[text] }}</span>
        </v-chip>
        <span
            v-if="index === 1"
            class="grey--text caption"
        >(+{{ selectedDatas.length - 1 }} {{$t('common.titles.others')}})</span>
      </template>
      <template v-slot:item="{ item, attrs }">
        <div style="max-width:360px">
          <v-checkbox :input-value="attrs.inputValue"
                      color="primary"
                      class="pt-2 pb-2 mt-0"
                      :hide-details="true"
          >
            <template v-slot:label>
              <read-more :classes="'mb-0'"
                         :text="item[text]"
                         :max-chars="80"
                         :font-size="14"
              />
            </template>
          </v-checkbox>
        </div>
      </template>
      <template v-slot:prepend-item>
        <v-checkbox
            v-model="checkboxState"
            color="primary"
            class="pt-2 pb-2 mt-0 pl-4"
            :hide-details="true"
            @change="selectAll"
        >
          <template v-slot:label>
            <v-btn small
                   color="primary" x-small
                   text>
              {{$t('common.titles.selectAll')}}
            </v-btn>
          </template>
        </v-checkbox>
        <v-divider class="mb-2"></v-divider>
      </template>
    </v-autocomplete>
    <span v-if="showSelected && selectedDatas.length > 0">
      <v-sheet
        elevation="10"
        class="py-4 px-1 elevation-0 mb-4 d-flex justify-center"
        width="75vw"
        max-width="100%"
        outlined
      >
        <v-chip small>{{ $t('common.titles.totalSelected') }}: {{ selectedDatas.length }}</v-chip>
    </v-sheet>
    </span>

  </div>
</template>
<script>
import ReadMore from "./ReadMoreComponent";
import _ from "lodash";
export default {
  name: 'Autocomplete',
  props: {
    data: {
      type: Array
    },
    refer:{
      type: String,
      required: true
    },
    text:{
      type: String,
      required: true
    },
    value:{
      type: String,
      required: true
    },
    items:{
      type: Array,
      required: true
    },
    showSelected:{
      type: Boolean,
      required: false
    },
    rules:{
      type: Array,
      required: true
    },
    label:{
      type: String,
      required: true
    },
    small_prop:{
      type: Boolean
    },
    solo_prop:{
      type: Boolean
    },
    flat_prop:{
      type: Boolean
    },
    backgroundColor:{
      type: String
    },
    disabled_prop:{
      type: Boolean
    },
    returnObject:{
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    editedIndex: {
      type: Number,
      required: true,
      default: -1
    }
  },
  components: {
    ReadMore
  },
  data(){
    return {
      selectedDatas:[],
      checkboxState:false,
      page: 1, // Añadir esta línea
      itemsPerPage: 10, // y esta línea
    }
  },

  created() {
    if (this.editedIndex !== -1)
      this.selectedDatas = this.data
  },

  methods: {
    deleteItem(item, items) {
      _.forEach(items, (n, k) => {
        if (item && n)
          if (item.id === n.id) {
            items.splice(k, 1)
          }
      })
    },
    checkState(){
      if ( this.selectedDatas && this.selectedDatas.length === this.$refs[this.refer].filteredItems.length ){
        this.checkboxState = true;
      }else{
        this.checkboxState = false;
      }
    },
    selectAll() {
      if (this.checkboxState === false) {
        this.selectedDatas = []
      } else {
        let aux = []
        if (this.selectedDatas && this.selectedDatas.length > 0) {
          _.forEach(this.selectedDatas, object => {
            if (
                !_.find(this.$refs[this.refer].filteredItems, o => {
                  return o.id === object.id
                })
            )
              this.$refs[this.refer].filteredItems.push(object)
          })
          aux = this.$refs[this.refer].filteredItems
        } else {
          aux = this.$refs[this.refer].filteredItems
        }
        this.selectedDatas = aux
      }
      this.$emit('data:change', this.selectedDatas);
    },
  }
}
</script>
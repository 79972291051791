<template>
  <v-container fluid>
    <v-row class="d-flex" justify="space-between" align="center">
      <v-col cols="12" class="d-flex justify-end align-center items-center">
        <v-btn icon @click="$emit('openConfiguration')">
          <v-icon color="neuter">mdi-cog</v-icon>
        </v-btn>
        <v-btn icon @click="$emit('openAppearance')">
          <v-icon color="neuter pt-2">mdi-format-color-fill</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ProcessActions"
}
</script>

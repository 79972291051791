<template>
  <v-dialog v-model="dialog" width="500px" max-width="100%" persistent>
    <v-card>
      <v-card-title class="headline secondary t-bw-secondary--text d-flex align-center">
        <span>{{ $t("process.appearance") }}</span>
        <v-spacer />
        <IconPicker v-model="processSelected.appearance.icon"
          :current="processSelected.appearance.icon.replace('mdi-', '')"
          color="t-bw-secondary" />
      </v-card-title>
      <v-card-text class="pa-6">
        <div class="preview-box d-flex flex-column align-center mb-6">
          <div class="icon-preview-container"
            :style="{ backgroundColor: processSelected.appearance.color }">
            <v-icon size="64" :color="getContrastYIQ(processSelected.appearance.color)">
              {{ processSelected.appearance.icon }}
            </v-icon>
          </div>
        </div>

        <ColorPicker v-model="processSelected.appearance.color"
          :current="processSelected.appearance.color || '#424242'" />
      </v-card-text>

      <v-card-actions class="pa-6">
        <v-spacer />
        <v-btn color="neuter" small text @click="cancel">{{ $t("common.buttons.cancel") }}</v-btn>
        <v-btn color="primary" small elevation="0" class="t-bw-primary--text" @click="save">{{
          $t("common.buttons.confirm") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import IconPicker from "@/module/dashboard/components/common/IconPicker.vue";
import ColorPicker from "@/module/dashboard/components/common/ColorPicker.vue";

export default {
  name: "ProcessAppearanceDialog",
  components: { IconPicker, ColorPicker },
  props: {
    value: { type: Boolean, required: true }
  },
  data() {
    return {
      dialog: this.value,
      originalState: {
        icon: 'mdi-sitemap',
        color: '#424242'
      }
    }
  },
  computed: {
    processSelected: {
      get() {
        return this.$store.getters['proccess/procces'];
      },
      set(val) {
        this.$store.commit('proccess/SET_PROCESS', val)
      }
    }
  },
  watch: {
    value(val) {
      this.dialog = val;
      if (val) {
        // Guardar estado inicial
        if (this.processSelected.appearance) {
          this.originalState = {
            icon: this.processSelected.appearance.icon,
            color: this.processSelected.appearance.color
          };
        } else {
          this.originalState = {
            icon: 'mdi-sitemap',
            color: '#424242'
          };
        }
        console.log(this.originalState);
      }
    },
    dialog(val) {
      this.$emit("input", val);
    }
  },
  methods: {
    save() {
      this.dialog = false;
    },
    cancel() {
      this.$set(this.processSelected.appearance, 'icon', this.originalState.icon);
      this.$set(this.processSelected.appearance, 'color', this.originalState.color);
      this.dialog = false;
    },
    getContrastYIQ(hexcolor) {
      if (!hexcolor?.startsWith('#')) return 'white';
      const r = parseInt(hexcolor.substr(1, 2), 16);
      const g = parseInt(hexcolor.substr(3, 2), 16);
      const b = parseInt(hexcolor.substr(5, 2), 16);
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      return (yiq >= 128) ? 'black' : 'white';
    }
  }
}
</script>

<style scoped>
.preview-box {
  min-height: 150px;
  width: 100%;
}

.icon-preview-container {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
</style>

<template>
  <v-container fluid>
    <v-container fluid class="my-0 py-0">
      <v-overlay :value="!ready" absolute color="white" :opacity="100">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        />
      </v-overlay>
      <HeaderProcessComponent @update:validation-one="valid1 = $event" @update:process-type="setDefaultStructures()" :disable-s-d-t="disableSDT" :ready="ready" />
      <v-row>
        <v-col class="ma-0 pa-0 pr-1" cols="6">
          <DataTableStructureComponent @update:form-validation="validateStructureForm()" />
        </v-col>
        <v-col class="ma-0 pa-0 pl-1" cols="6">
          <DataTableGroupStructureComponent @update:form-validation="validateStructureForm()" />
        </v-col>
      </v-row>

    </v-container>
    <v-container fluid class="mb-5">
      <v-form class="d-flex justify-center align-center" ref="form1"
              v-model="valid2">
        <v-row>
          <v-col class="ma-0 pa-0 pr-1" cols="6">
            <p class="headline font-weight-regular" style="color: #232323">
              {{ $t('process.titles.details_process') }}
            </p>
            <v-card class="mt-3 pa-6"
                    outlined
                    v-if="!(structuresSelected.length > 0)"
            >
              <v-card-text class="text-lg-center">
                {{ $t('process.titles.nothingSelectedDetail') }}
              </v-card-text>
            </v-card>
            <draggable v-model="structuresSelected"
                       @start="dragStructure=true"
                       @end="dragStructure=false"
                       @change="onListChanged($event)"
            >
              <v-card v-for="(structure, index) in structuresSelected" outlined class="mt-3">
                <v-card-title class="justify-end pb-0">
                  <v-btn icon>
                    <v-icon>
                      mdi-drag
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <StructureComponent
                    :edited-index="editedIndex"
                    :key="structure.id"
                    :structure="structure"
                    :config="config"
                    :ref-key="structure.id"
                    @update:process-details="$set(proccessDetails, structure.id, $event)"
                    @update:selected-all="$set(structure, 'selectAll', $event)"
                  />
                </v-card-text>
              </v-card>
            </draggable>
          </v-col>
          <v-col class="ma-0 pa-0 pl-1" cols="6">
            <p class="headline font-weight-regular" style="color: #232323">
              {{ $t('process.titles.groupsDetails') }}
            </p>
            <v-card class="mt-3 pa-6"
                    outlined
                    v-if="!(groupStructuresSelected.length > 0)"
            >
              <v-card-text class="text-lg-center">
                {{ $t('process.titles.nothingSelectedGroups') }}
              </v-card-text>
            </v-card>
            <draggable v-model="groupStructuresSelected"
                       @start="dragGroup=true"
                       @end="dragGroup=false"
            >
              <v-card class="mt-3" outlined v-for="(groupStructures, index) in groupStructuresSelected" :key="groupStructures.id">
                <v-card-title>
                  <span class="neuter--text">{{ translate(groupStructures.languageKey, true) }}</span>
                  <v-chip small light disabled style="border-radius: .3em" class="mx-2">
                    {{ groupStructures.structure.length }}
                  </v-chip>
                  <v-spacer></v-spacer>
                  <div v-if="isDefaultGroup(groupStructures.groupCode)">
                    <v-btn @click="openAddStructureDialog(index)" tile depressed small elevation="0" color="primary" class="expanding-button__add mx-4" style="border-radius: .2rem">
                      <v-icon>mdi-plus</v-icon>
                      <span class="button-text">{{ $t('common.buttons.add') }}</span>
                    </v-btn>
                  </div>
                  <v-btn tile depressed small elevation="0"  style="border-radius: .2rem">
                    <v-icon size="18" @click="groupStructures.compress = !groupStructures.compress" v-if="groupStructures.compress">
                      mdi-arrow-collapse-vertical
                    </v-icon>
                    <v-icon size="18" @click="groupStructures.compress = !groupStructures.compress" v-if="!groupStructures.compress">
                      mdi-arrow-split-horizontal
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="groupStructures.compress">
                  <draggable
                    v-model="groupStructures.structure"
                    @start="drag=true"
                    @end="drag=false"
                    @change="onListChanged($event)"
                  >
                    <div v-for="(structureOfGroup, index) in groupStructures.structure">
                      <div style="position: relative">
                        <!--<div style="position: absolute; right: 0">
                          <v-btn v-if="isDefaultGroup(groupStructures.groupCode) && !isDefaultStructure(structureOfGroup.structureCode)"
                                 @click="deleteStructure(groupStructures, structureOfGroup)"
                                 tile depressed x-small elevation="0" color="error" class="expanding-button__delete ml-12" style="border-radius: .2rem .2rem 0 0">
                            <v-icon small>mdi-close</v-icon>
                            <span class="button-text">{{ $t('common.buttons.delete') }}</span>
                          </v-btn>
                        </div>-->

                        <StructureComponent
                          :edited-index="editedIndex"
                          :key="structureOfGroup.id"
                          :structure="structureOfGroup"
                          :config="config"
                          :ref-key="`${structureOfGroup.id}-${groupStructures.id}`"
                          @update:process-details="$set(proccessGroupDetails, `${structureOfGroup.id}-${groupStructures.id}`, $event)"
                          @update:selected-all="$set(structureOfGroup, 'selectAll', $event)"
                        />
                      </div>

                    </div>
                  </draggable>
                </v-card-text>
              </v-card>
              <v-dialog v-model="addStructureDialog" max-width="600px">
                <v-card>
                  <v-card-title class="headline secondary t-bw-secondary--text">
                    {{ $t('structure.titles.new_structure') }}
                  </v-card-title>
                  <v-card-text class="pa-6">
                    <v-select
                      v-model="selectedStructures"
                      :items="filteredItems"
                      item-text="details_name"
                      item-value="id"
                      :label="$t('common.titles.details')"
                      outlined
                      dense
                      color="primary"
                      item-color="primary"
                      return-object
                      multiple
                      hide-selected
                      :clearable="false"
                      item-disabled="disabled"
                      chips
                      small-chips
                      :no-data-text="$t('common.fields.noDataAvailable')"
                    >
                      <template v-slot:prepend-item>
                        <div class="ma-2">
                          <v-text-field
                            v-model="search"
                            label="Buscar"
                            dense
                            outlined
                            hide-details
                            color="primary"
                          ></v-text-field>
                        </div>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0" small>
                          <span>{{ item.details_name }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption">
                          (+{{ selectedStructures.length - 1 }} {{ $t("common.titles.others") }})
                        </span>
                      </template>
                      <template v-slot:item="{ item, on, attrs }">
                        <v-list-item :disabled="item.disabled"
                                     v-on="on"
                                     v-bind="attrs"
                                     class="cursor-pointer"
                        >
                          <v-list-item-content :class="{ 'strikethrough': item.disabled }">
                            {{ item.details_name }}
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                    <v-chip-group v-if="selectedStructures && selectedStructures.length > 0">
                      <v-chip
                        v-for="(structure, index) in selectedStructures"
                        :key="structure.id"
                        :close="!isDefaultStructure(structure.structureCode)"
                        :disabled="isDefaultStructure(structure.structureCode)"
                        @click:close="deleteSelectedStructure(selectedStructures, index)"
                      >
                        {{ structure.details_name }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>
                  <v-card-actions class="pa-6">
                    <v-spacer />
                    <v-btn small color="neuter" text @click="addStructureDialog = false">{{ $t('common.buttons.cancel') }}</v-btn>
                    <v-btn small color="primary" elevation="0" class="t-bw-primary--text" @click="addStructureToGroup(selectedGroupIndex, selectedStructures)">{{ $t('common.buttons.confirm') }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </draggable>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <!-- Inserción de los nuevos diálogos -->
    <ProcessConfigDialog
      v-model="configuration"
      :processSelected="processSelected"
      @update:processSelected="processSelected = $event"
    />
    <ProcessAppearanceDialog
      v-model="appearanceDialog"
      @update:processAppearance="updateProcessAppearance"
    />
    <v-app-bar bottom elevation="0" color="transparent">
      <v-spacer></v-spacer>
      <v-btn class="mr-2" :disabled="loadSave" small color="neuter" text @click="cancel">
        {{$t('common.buttons.cancel')}}
      </v-btn>
      <v-btn :loading="loadSave" small elevation="0" class="t-bw-primary--text" color="primary"
             @click="save"
             :disabled="!validStructureForm">
        {{$t('common.buttons.continue')}}
      </v-btn>
    </v-app-bar>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import i18n from '@/plugins/i18n';
import _ from "lodash";
import CardList from '@/components/CardList';
import draggable from 'vuedraggable'
import LanguajeService from "@/services/LanguajeService";
import ReadMore from "@/components/ReadMoreComponent";
import Autocomplete from '@/components/Autocomplete';
import DataTableStructureComponent
  from "@/module/configuration/process/components/common/DataTableStructureComponent.vue";
import DataTableGroupStructureComponent
  from "@/module/configuration/process/components/common/DataTableGroupStructureComponent.vue";
import HeaderProcessComponent from "@/module/configuration/process/components/common/HeaderProcessComponent.vue";
import StructureComponent from "@/module/configuration/process/components/common/StructureComponent.vue";
import SingleStructureComponent from "@/module/prerecords/commons/SingleStructureComponent.vue";
import ProcessConfigDialog from "@/module/configuration/process/components/common/ProcessConfigDialog.vue";
import ProcessAppearanceDialog from "@/module/configuration/process/components/common/ProcessAppearanceDialog.vue";
import ProcessActions from "@/module/configuration/process/components/common/ProcessActions.vue";

const NOMENCLATURES = ['PRODUCT', 'EQUIPMENT', 'INPUT', 'SEASON', 'OPERATOR', 'MEASUREMENTUNIT', 'PRODUCTSIMPLE', 'EQUIPMENTSIMPLE', 'OPERATORSIMPLE', 'INPUTSIMPLE', 'SEASONSIMPLE', 'MEASUREMENTUNITSIMPLE'];
const OTHERS = ['TEXT', 'NUMBER', 'LOTE', 'LACRE', 'QR', 'TIME', 'DATA', 'DATATIME'];

export default {
  name: "TabMainProcess",
  components: {
    SingleStructureComponent,
    StructureComponent,
    HeaderProcessComponent,
    DataTableGroupStructureComponent,
    DataTableStructureComponent,
    ReadMore,
    CardList,
    draggable,
    Autocomplete,
    ProcessActions,
    ProcessConfigDialog,
    ProcessAppearanceDialog
  },
  data() {
    return {
      editedIndex: -1,
      //drag
      drag: false,
      dragGroup: false,
      dragStructure: false,
      // STEPERS
      valid1: false,
      valid2: false,
      validConfig: true,
      loadSave: false,
      structures: [],
      proccessDetails: {},
      proccessGroupDetails: [],
      structuresOptional: ['LACRE', 'QR', 'TEXT', 'NUMBER', 'LOTE', 'TIME', 'DATA', 'DATATIME'],
      disableSDT: false,
      ready: true,
      configuration: false,
      reportTypes: [{
        title: i18n.t('reports.titles.traceability-report'),
        value: "TRACEABILITY"
      }, {
        title: i18n.t('reports.titles.recall-report'),
        value: "RECALL"
      }],
      addStructureDialog: false,
      selectedStructures: [],
      selectedGroupIndex: null,
      search: '',
      switches: {
        automaticTraceability: false
      },
      appearanceDialog: false
    }
  },

  async created() {
    this.config = JSON.parse(localStorage.getItem('config'))
    await this.initialize();
    this.order();
  },

  mounted() {
    this.$root.$on('clear:process-data', () => {
      this.cancel();
    });
  },

  computed: {
    ...mapGetters({
      processTypeEnum: 'proccess/processTypeEnum',
      details: 'details/details',
      groupDetailsByCompany: 'groupDetails/groupDetailsByCompany',
      groupDetailsDefaults: 'groupDetails/groupDetailsDefaults',
      detailsCode: 'details/detailsCode',
      required: 'general/requireRules',
      onlyPositiveNumberFormat: 'general/onlyPositiveNumberFormat',
      validUrlFormat: 'general/validUrlOrNull',
      languageStructure: 'general/languageStructure'
    }),
    filteredItems() {
      return _.map(this.details.filter(item => item.details_name.toLowerCase().includes(this.search.toLowerCase())), (d) => ({
        ...d,
        disabled: this.isDefaultStructure(d.structureCode)
      }));
    },
    isDefaultGroup() {
      return (code) => ['GI', 'GO', 'REEN'].includes(code)
    },
    isDefaultStructure() {
      return (code) => ['CI', 'CO', 'C', 'PI', 'PO', 'P', 'MUI', 'MUO', 'MU', 'LI', 'LO', 'L', 'S'].includes(code)
    },
    storagesTypesByCompany() {
      return _.filter(this.$store.getters['storage_type/storagesTypesByCompany'], {isActive: true});
    },
    config: {
      get() {
        return this.$store.getters['general/config']
      },
      set(val) {
        this.$store.commit('general/SET_CONFIG', val)
      }
    },
    // Form condition title
    formTitle() {
      return (this.editedIndex === -1 ? i18n.t('common.titles.new') : i18n.t('common.titles.edit')) + ' ' + i18n.t('process.titles.new_process');
    },
    processSelected: {
      get() {
        return this.$store.getters['proccess/procces'];
      },
      set(val) {
        this.$store.commit('proccess/SET_PROCESS', val)
      }
    },
    groupStructuresSelected: {
      get() {
        return this.$store.getters['proccess/groupStructuresSelected'];
      },
      set(val) {
        this.$store.commit('proccess/SET_GROUP_SELECTED', val)
      }
    },
    structuresSelected: {
      get() {
        return this.$store.getters['proccess/structuresSelected'];
      },
      set(val) {
        this.$store.commit('proccess/SET_STRUCTURES_SELECTED', val)
      }
    },
    validStructureForm() {
      this.valid2 = true;
      _.forEach(this.structuresSelected, (structure) => {
        if (!this.structuresOptional.includes(structure.structure_type) && (!this.proccessDetails[structure.id] || this.proccessDetails[structure.id].length === 0)) {
          this.valid2 = false;
        }
      });
      _.forEach(this.groupStructuresSelected, (group) => {
        _.forEach(group.structure, (structure, key) => {
          if (!this.structuresOptional.includes(structure.structure_type) && (!this.proccessGroupDetails[structure.id + '-' + group.id] || this.proccessGroupDetails[structure.id + '-' + group.id].length === 0)) {
            this.valid2 = false;
          }
        });
      });
      return this.valid2 && this.valid1;
    },
  },
  methods: {
    ...mapActions({
      fetchListAuthorityProcess: 'authority/fetchListAuthorityProcess',
      fetchListProccess: 'proccess/fetchListProccess',
      fetchStorageTypesActiveByCompany: 'storage_type/fetchStorageTypesActiveByCompany',
      fetchListStructures: 'details/fetchListStructures',
      fetchListStructuresByCode: 'details/fetchListStructuresByCode',
      saveProccess: 'proccess/saveProccess',
      deleteProccess: 'proccess/deleteProccess',
      fetchAllGroupUnits: 'units/fetchAllGroupUnits',
    }),
    onChangeAutomaticTraceability(e) {
      this.$set(this.processSelected, 'automaticTraceability', e);

      if (!e) {
        this.processSelected.reportType = null;
        this.$refs.validConfig.resetValidation();
        this.validConfig = true;
      }
    },
    deleteStructure(groupStructures, structureOfGroup) {
      // Implementa la lógica para eliminar la estructura
      // Por ejemplo, puedes eliminarla del array de estructuras del grupo
      const index = groupStructures.structure.indexOf(structureOfGroup);
      if (index > -1) {
        groupStructures.structure.splice(index, 1);
      }
    },
    deleteSelectedStructure(groupStructures, index) {
      if (index > -1) {
        groupStructures.splice(index, 1);
      }
    },
    openAddStructureDialog(index) {
      this.selectedGroupIndex = index;
      this.selectedStructures = [...this.groupStructuresSelected[index].structure];
      this.addStructureDialog = true;
    },
    addStructureToGroup(index, structures) {
      if ((structures && structures.length > 0) && index !== null) {
        this.groupStructuresSelected[index].structure = [...structures];
        this.addStructureDialog = false;
        this.selectedStructures = [];
      }
    },
    onListChanged(e, ref) {
      return;
    },
    validateStructureForm() {
      this.$refs.form1.validate()
    },
    addDisposition(target, origin) {
      target['details_name'] = this.translate(origin.structure.language_key);
      target['disposition'] = origin.structure.disposition;
      target['order'] = origin.structure.disposition;
      target['is_required'] = origin.structure.is_required;
      target['dataToSelect'] = origin.structure.dataToSelect;
      target['data_source'] = origin.structure.data_source;
      target['processStructureId'] = origin.recordStructureValue.processStructureId.id;
    },
    translate(key, uppercase) {
      return LanguajeService.getKey3(key)
    },
    async initialize() {
      const promises = [];
      this.profile = JSON.parse(localStorage.getItem('profile'));
      this.ready = false;
      promises.push(this.fetchStorageTypesActiveByCompany([this.$toast]));
      promises.push(this.fetchListStructures([this.profile, this.$toast]));
      promises.push(this.fetchListStructuresByCode([this.$toast]));
      promises.push(this.fetchAllGroupUnits(this.$toast));
      await Promise.all(promises).finally(() => {
        if (!this.processSelected.appearance) {
          this.$set(this.processSelected.appearance, 'icon', 'mdi-sitemap');
          this.$set(this.processSelected.appearance, 'color', '#424242');
        }

        if (this.processSelected.id > 0) {
          localStorage.setItem('process:version', this.processSelected.processVersionId)
          localStorage.setItem('process:type', this.processSelected.processType)

          console.log(this.processSelected);

          this.switches.automaticTraceability = this.processSelected.automaticTraceability;

          this.disableSDT = false;
          if (this.processSelected.sdt_to_show) {
            const sdt_to_show = this.storagesTypesByCompany.find(x => x.storage_device_type_id === this.processSelected.sdt_to_show.id);
            if (sdt_to_show) {
              this.$set(this.processSelected, 'sdt_to_show', sdt_to_show);
            } else {
              this.disableSDT = true;
            }
          }
          this.processSelected.processType = this.processTypeEnum.find(x => x.value === this.processSelected.processType);

          this.editedIndex = this.processSelected.id;
          _.forEach(this.processSelected.processStructureOrganized.structures, (structure) => {
            const item = _.cloneDeep(_.find(this.details, (o) => {
              return o.id === structure.structure.id;
            }));
            if (item) {
              item.disposition = structure.structure.disposition;
              this.structuresSelected.push({
                ...structure.structure,
                ...item,
                processStructureId: structure.recordStructureValue.processStructureId.id
              })
            } else {
              const itemCode = _.find(this.detailsCode, (o) => {
                return o.id === structure.structure.id;
              });
              if (itemCode) {
                itemCode.disposition = structure.structure.disposition;
                this.structuresSelected.push({
                  ...structure.structure,
                  ...itemCode,
                  processStructureId: structure.recordStructureValue.processStructureId.id
                });
              }
            }
          });

          _.forEach(this.processSelected.processStructureOrganized.groups, (groupOrg) => {
            let group = _.find(this.groupDetailsByCompany, (o) => {
              return o.id === groupOrg.id;
            }) || _.find(this.groupDetailsDefaults, (o) => {
              return o.id === groupOrg.id;
            });

            if (!group) {
              console.error('Grupo no encontrado en los detalles por defecto ni en los detalles de la compañía.');
              return;
            }

            // Actualizar disposición y orden desde los datos originales del proceso
            group.disposition = groupOrg.disposition;
            group.order = groupOrg.disposition;

            // Asegúrate de que la propiedad 'structure' esté inicializada en group
            group.structure = group.structure || [];

            // Itera sobre las estructuras del grupo original
            _.forEach(groupOrg.rows[0].values, (structure) => {
              const target = _.find(group.structure, { id: structure.structure.id });

              if (target) {
                // Si la estructura ya existe, actualízala
                this.addDisposition(target, structure);
              } else {
                // Si la estructura no existe, añádela a 'group.structure'
                const newStructure = {
                  ...structure.structure,
                  processStructureId: structure.recordStructureValue.processStructureId.id
                };
                group.structure.push(newStructure);
                this.addDisposition(newStructure, structure);
              }
            });

            // Añade o actualiza el grupo en groupStructuresSelected
            this.groupStructuresSelected.push(group);
          });
        }
        this.ready = true;
      });
    },

    clearLocalStorage() {
      localStorage.removeItem('process:data');
      localStorage.removeItem('process:sdt')
      localStorage.removeItem('process:type')
      localStorage.removeItem('process:version')
    },

    cancel() {
      this.clearLocalStorage();
      this.$root.$emit('update:nomenclator-model', []);
      this.$root.$emit('clear:process-details', []);
      this.$set(this, 'structures', []);
      this.$set(this, 'proccessDetails', {});
      this.$set(this, 'proccessGroupDetails', []);
      this.$store.commit('proccess/SET_ORIGINAL_STATE_PROCESS');
      this.$store.commit('proccess/SET_GROUP_SELECTED', [])
      this.$store.commit('proccess/SET_STRUCTURES_SELECTED', [])
      this.$router.push('/nomenclators/proccess');
    },

    async save() {
      this.loadSave = true;
      let item = Object.assign({}, {...this.processSelected});
      item.company = {id: this.profile.company_id};

      // Manejo correcto de sdt_to_show
      if (item.sdt_to_show) {
        // Si tiene storage_device_type_id, usamos ese
        if (item.sdt_to_show.storage_device_type_id) {
          item.sdt_to_show = { id: item.sdt_to_show.storage_device_type_id };
        }
        // Si tiene id directo, lo mantenemos
        else if (item.sdt_to_show.id) {
          item.sdt_to_show = { id: item.sdt_to_show.id };
        }
        // Si no tiene ninguno de los dos, establecemos null
        else {
          item.sdt_to_show = null;
        }
      } else {
        item.sdt_to_show = null;
      }

      // Guardamos en localStorage solo si tenemos un valor válido
      if (item.sdt_to_show) {
        localStorage.setItem('process:sdt', JSON.stringify(item.sdt_to_show));
      } else {
        localStorage.removeItem('process:sdt');
      }

      item.processType = item.processType.value ? item.processType.value : item.processType;
      // Translate
      item.language_key = LanguajeService.setKey3(item.proccess_name, item.language_key, !item.id ? this.languageStructure : null);

      let orderGroup = [];
      _.forEach(this.structuresSelected, (value, key) => {
        this.structureAddForSave(item, this.proccessDetails, value, key, false);
      });
      _.forEach(this.groupStructuresSelected, (value, key) => {
        value.disposition = key + 1;
        orderGroup.push({
          disposition: key + 1,
          groupStructure: value,
          group_structure_id: value.id,
          process_id: item.id
        });
        _.forEach(value.structure, (structure, key) => {
          this.structureAddForSave(item, this.proccessGroupDetails, structure, key, true, value.id);
        });
      });
      item.structures = this.structures;
      item.groupStructures = orderGroup;

      await this.saveProccess([item, this.editedIndex, this.$toast])

      await this.fetchListProccess([this.profile, this.$toast]).finally(() => {
        this.loadSave = false;
        if (this.editedIndex === -1) {
          this.editedIndex = this.processSelected.id;
          localStorage.setItem('process:version', this.processSelected.processVersionId)
        }
        localStorage.setItem('process:type', this.processSelected.processType)
        // this.$store.commit('proccess/SET_ORIGINAL_STATE_PROCESS');
        // this.$store.dispatch('authority/fetchListAuthorityProcess', [this.profile.id, this.$toast]);
        // this.$router.push({path: '/nomenclators/proccess'});
        this.structures = [];
        this.$emit('change:tab', 1);
      });
    },

    structureAddForSave(process, processDetails, value, key, isGroup, groupId) {
      let group = null;
      const keyIndex = groupId ? value.id + '-' + groupId : value.id;
      if (isGroup) {
        group = {id: groupId};
      }
      const lists = ['LIST', 'LISTSIMPLE'];
      if (lists.includes(value.structure_type)) {
        let dataSource = [];
        _.forEach(processDetails[keyIndex], (details, key) => {
          dataSource.push({
            id: null,
            name: LanguajeService.setKey3(details, null, this.languageStructure)
          });
        });
        this.structures.push({
          structure: {id: value.id},
          is_required: value.is_required ? 1 : 0,
          disposition: key + 1,
          structure_type: value.structure_type,
          data_source: dataSource,
          groupStructures: group
        })
      }
      if (NOMENCLATURES.includes(value.structure_type)) {
        let dataToSelect = [];
        _.forEach(processDetails[keyIndex], (details) => {
          dataToSelect.push(details.id);
        });
        this.structures.push({
          structure: {id: value.id},
          is_required: value.is_required ? 1 : 0,
          disposition: key + 1,
          structure_type: value.structure_type,
          dataToSelect: dataToSelect,
          groupStructures: group,
          selectAll: value.selectAll === 'undefined' ? false : value.selectAll
        })
      }
      if (OTHERS.includes(value.structure_type)) {
        this.structures.push({
          structure: {id: value.id},
          is_required: value.is_required ? 1 : 0,
          disposition: key + 1,
          structure_type: value.structure_type,
          groupStructures: group
        })
      }
    },

    setDefaultStructures() {
      const scope = this;
      let defaultsDetails = [];
      let defaultsGroupDetails = [];
      scope.structuresSelected = [];
      scope.groupStructuresSelected = [];
      if (scope.processSelected.processType) {
        if (scope.processSelected.processType.value === 'PROCESSING') {
          defaultsGroupDetails = _.filter(scope.groupDetailsDefaults, (o) => {
            return (o.groupCode === 'GO' || o.groupCode === 'GI') && o.company.id === 0
          });
        } else if (scope.processSelected.processType.value === 'AGRICULTURAL') {
          console.log(scope.detailsCode)
          defaultsDetails = _.filter(scope.detailsCode, (o) => {
            return (o.structureCode === 'S' || o.structureCode === 'P') && o.company.id === 0
          });
        } else {
          const group = _.filter(scope.groupDetailsDefaults, (o) => {
            return o.groupCode === 'REEN' && o.company.id === 0
          });
          // Solución Temporal ********
          let structures = [];

          structures.push(_.find(group[0].structure, o => o.structureCode === 'P' && o.company.id === 0));
          structures.push(_.find(group[0].structure, o => o.structureCode === 'C' && o.company.id === 0));
          structures.push(_.find(group[0].structure, o => o.structureCode === 'MU' && o.company.id === 0));
          structures.push(_.find(group[0].structure, o => o.structureCode === 'L' && o.company.id === 0));

          structures = structures.filter(s => s != null);

          group[0].structure = structures;
          defaultsGroupDetails = group;
          // Solución Temporal ********
        }
      }

      _.forEach(defaultsDetails, (value) => {
        if (this.editedIndex !== -1)
          this.$root.$emit('initNomenclatorData', value)

        if (!_.find(scope.structuresSelected, {'id': value.id})) {
          scope.structuresSelected.push(value);
        }
      });
      _.forEach(defaultsGroupDetails, (value) => {
        if (this.editedIndex !== -1) {
          _.forEach(value.structure, (s) => {
            this.$root.$emit('initNomenclatorData', s)
          })
        }

        if (!_.find(scope.groupStructuresSelected, {'id': value.id})) {
          scope.groupStructuresSelected.push(value);
        }
      });

      // scope.order();
    },
    order() {
      this.structuresSelected = _.orderBy(this.structuresSelected, 'disposition');
      this.groupStructuresSelected = _.orderBy(_.map(this.groupStructuresSelected, (structureGroup) => {
        return {
          ...structureGroup,
          structure: _.orderBy(structureGroup.structure, 'disposition'),
          compress: true
        }
      }), 'disposition');
    },
    updateProcessAppearance(appearance) {
      this.$set(this.processSelected.appearance, 'icon', appearance.icon);
      this.$set(this.processSelected.appearance, 'color', appearance.color);
    }
  },

  destroyed() {
    this.clearLocalStorage()
    this.$set(this, 'proccessDetails', {})
    this.$set(this, 'proccessGroupDetails', [])
    this.$store.commit('proccess/SET_ORIGINAL_STATE_PROCESS');
    localStorage.removeItem('process:data')
  }
}
</script>

<style scoped>
.expanding-button__add,
.expanding-button__delete {
  position: relative;
  overflow: hidden;
  transition: width 0.3s ease;
  justify-content: flex-start;

  .v-icon {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.expanding-button__add {
  width: 40px;
}

.expanding-button__delete {
  width: 30px;
}

.expanding-button__add:hover {
  width: 125px;
}

.expanding-button__delete:hover {
  width: 80px;
}

.button-text {
  transition: transform, opacity  0.3s ease;
  opacity: 0;
  transform: translateX(-100%);
  display: inline-block;
}

.expanding-button__add:hover .button-text {
  opacity: 1;
  transform: translateX(0%);
}

.expanding-button__delete:hover .button-text {
  opacity: 1;
  transform: translateX(0%);
}

.strikethrough {
  text-decoration: line-through;
  color: grey;
}
</style>

<template>
  <v-dialog v-model="dialog" :persistent="!localValid" width="700px" max-width="100%" closable>
    <v-card elevation="0" width="700px" max-width="100%">
      <v-card-title class="headline secondary t-bw-secondary--text d-flex justify-space-between">
        <span>{{ $t("menu.configuration") }}</span>
        <v-icon color="t-bw-secondary" :disabled="!localValid" @click="close">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text class="pa-6">
        <v-form v-model="localValid" ref="validForm">
          <v-row class="d-flex">
            <v-col cols="6" class="d-flex justify-space-between align-start align-content-start">
              <v-card outlined class="pa-4 d-flex flex-column mx-auto" style="width: 100%">
                <v-switch inset class="mx-2 ma-0 pa-0" v-model="localProcess.isInternal" color="primary" hide-details>
                  <template v-slot:label>
                    <label class="pt-1" ref="label">{{ $t("process.fields.internalStock") }}</label>
                  </template>
                </v-switch>
              </v-card>
            </v-col>
            <v-col cols="6" class="d-flex justify-space-between align-start align-content-start">
              <v-card outlined class="pa-4 d-flex flex-column mx-auto" style="width: 100%">
                <v-switch inset class="mx-2 ma-0 pa-0" v-model="localProcess.printLimit" color="primary" hide-details>
                  <template v-slot:label>
                    <label class="pt-1" ref="label">{{ $t("reports.titles.printLimit") }}</label>
                  </template>
                </v-switch>
              </v-card>
            </v-col>
            <v-col cols="6" class="d-flex justify-space-between align-start align-content-start">
              <v-card outlined class="pa-4 d-flex flex-column mx-auto" style="width: 100%">
                <v-switch inset class="mx-2 ma-0 pa-0" v-model="localProcess.redirectQr" color="primary" hide-details>
                  <template v-slot:label>
                    <label class="pt-1" ref="label">{{ $t("process.fields.redirectQr") }}</label>
                  </template>
                </v-switch>
              </v-card>
            </v-col>
            <v-col cols="6" class="d-flex justify-space-between align-start align-content-start">
              <v-card outlined class="pa-4 d-flex flex-column mx-auto" style="width: 100%">
                <v-switch inset class="mx-2 ma-0 pa-0" v-model="localProcess.isOrderType" color="primary" hide-details>
                  <template v-slot:label>
                    <label class="pt-1" ref="label">{{ $t("process.fields.isOrderType") }}</label>
                  </template>
                </v-switch>
              </v-card>
            </v-col>
            <v-col cols="6" class="d-flex justify-space-between align-start align-content-start">
              <v-card outlined class="pa-4 d-flex flex-column mx-auto" style="width: 100%">
                <v-switch inset class="mx-2 ma-0 pa-0" v-model="localProcess.inheritStatus" color="primary" hide-details>
                  <template v-slot:label>
                    <label class="pt-1" ref="label">{{ $t("process.fields.inheritStatus") }}</label>
                  </template>
                </v-switch>
              </v-card>
            </v-col>

            <!-- Automatic Traceability Section - Only shows if not AGRICULTURAL -->
            <v-col cols="12" v-if="localProcess.processType && localProcess.processType.value !== 'AGRICULTURAL'">
              <v-card outlined class="pa-4 d-flex flex-column mx-auto" style="width: 100%">
                <span class="d-flex justify-space-between">
                  <v-switch inset class="mx-2 ma-0 pa-0" 
                           v-model="switches.automaticTraceability" 
                           @change="onChangeAutomaticTraceability" 
                           color="primary" 
                           hide-details
                  >
                    <template v-slot:label>
                      <label class="pt-1" ref="label" style="width: 200px">
                        {{ $t('process.fields.automaticTraceability') }}
                      </label>
                    </template>
                  </v-switch>
                  <v-autocomplete
                    :disabled="!switches.automaticTraceability"
                    :no-data-text="$t('common.fields.noDataAvailable')"
                    small
                    outlined
                    dense
                    color="primary"
                    clearable
                    item-color="primary"
                    :items="reportTypes"
                    item-text="title"
                    item-value="value"
                    v-model="localProcess.reportType"
                    small-chips
                    :label="$t('documents.titles.selectExpired')"
                    :rules="switches.automaticTraceability ? required: []"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip small>
                        <span>{{ item.title }}</span>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </span>
                <v-alert
                  v-if="switches.automaticTraceability && !localProcess.reportType"
                  dense
                  text
                  type="warning"
                  border="left"
                >
                  <span v-html="$t('process.titles.reportSelectWarning')"></span>
                </v-alert>
              </v-card>
            </v-col>

            <!-- Check Stock - Only shows for SHIPPING or PROCESSING -->
            <v-col cols="6" class="d-flex justify-space-between align-start align-content-start"
                   v-if="localProcess.processType && ['SHIPPING', 'PROCESSING'].includes(localProcess.processType.value)"
            >
              <v-card outlined class="pa-4 d-flex flex-column mx-auto" style="width: 100%">
                <v-switch inset class="mx-2 ma-0 pa-0" v-model="localProcess.checkStock" color="primary" hide-details>
                  <template v-slot:label>
                    <label class="pt-1" ref="label">
                      {{$t('process.fields.checkStock')}}
                    </label>
                  </template>
                </v-switch>
              </v-card>
            </v-col>
            <v-col cols="6" class="d-flex justify-space-between align-start align-content-start">
              <v-card outlined class="pa-4 d-flex flex-column mx-auto" style="width: 100%">
                <v-switch inset class="mx-2 ma-0 pa-0" v-model="processSelected.automaticStock" color="primary" hide-details>
                  <template v-slot:label>
                    <label class="pt-1" ref="label">
                      {{$t('process.fields.automaticStock')}}
                    </label>
                  </template>
                </v-switch>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer />
        <v-btn small color="neuter" text @click="close">{{ $t('common.buttons.cancel') }}</v-btn>
        <v-btn small color="primary" elevation="0" class="t-bw-primary--text" @click="save">{{ $t('common.buttons.confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "ProcessConfigDialog",
  props: {
    value: { type: Boolean, required: true },
    processSelected: { type: Object, required: true }
  },
  data() {
    return {
      dialog: this.value,
      localProcess: { ...this.processSelected },
      localValid: true,
      switches: {
        automaticTraceability: this.processSelected.automaticTraceability || false
      },
      reportTypes: [{
        title: this.$t('reports.titles.traceability-report'),
        value: "TRACEABILITY"
      }, {
        title: this.$t('reports.titles.recall-report'),
        value: "RECALL"
      }]
    }
  },
  computed: {
    ...mapGetters({
      required: 'general/requireRules'
    })
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit("input", val);
    }
  },
  methods: {
    close() {
      this.dialog = false;
    },
    handlePrintLimitChange(value) {
      this.localProcess.printLimit = value ? 1 : 0;
    },
    save() {
      const processToSave = { ...this.localProcess };
      
      // Asegurar que sdt_to_show tenga id si existe
      if (processToSave.sdt_to_show && !processToSave.sdt_to_show.id) {
        processToSave.sdt_to_show = null;
      }

      // Asegurar que printLimit sea booleano
      processToSave.printLimit = Boolean(processToSave.printLimit);

      this.$emit("update:processSelected", processToSave);
      this.dialog = false;
    },
    onChangeAutomaticTraceability(e) {
      this.localProcess.automaticTraceability = e;
      if (!e) {
        this.localProcess.reportType = null;
        this.$refs.validForm.resetValidation();
        this.localValid = true;
      }
    }
  }
}
</script>

<template>
  <v-form ref="form" v-model="valid" v-if="!!ready">
    <v-row class="my-4">
      <v-col col="6" class="ma-0 pa-0 pr-1">
        <v-text-field
          onkeypress="return (event.charCode != 34)"
          v-on:keyup.86="replaceProccessName"
          v-on:keyup.ctrl.86="replaceProccessName"
          v-model="processSelected.proccess_name"
          :rules="requireAndMaxRules(max)"
          :counter="max"
          maxlength="max"
          class="required"
          color="primary"
          persistent-hint
          small
          outlined
          dense
        >
          <template v-slot:label>
            {{ $t("process.fields.name") }}
            <strong class="red--text">*</strong>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="3" class="ma-0 pa-0 px-1">
        <v-combobox
          :no-data-text="$t('common.fields.noDataAvailable')"
          :clearable="true"
          :hide-selected="true"
          :items="orderDatas(processTypeEnum, 'text')"
          :rules="requireRules"
          item-text="text"
          item-value="value"
          v-model="processSelected.processType"
          @change="$emit('update:process-type')"
          class="required"
          color="primary"
          persistent-hint
          small
          outlined
          dense
          :disabled="!!processSelected.id"
        >
          <template v-slot:label>
            {{ $t("process.fields.type") }}
            <strong class="red--text">*</strong>
          </template>
          <template v-slot:selection="{ item }">
            {{ $t(`process.fields.select.${item.value ? item.value : item}`) }}
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="3" class="ma-0 pa-0 pl-1">
        <v-combobox
          :no-data-text="$t('common.fields.noDataAvailable')"
          :clearable="true"
          :hide-selected="true"
          :items="storagesTypesByCompany"
          :rules="requireRules"
          item-text="storage_type_name"
          item-value="id"
          v-model="processSelected.sdt_to_show"
          v-if="!disableSDT"
          class="required"
          color="primary"
          persistent-hint
          small
          outlined
          dense
          :disabled="!!processSelected.id"
        >
          <template v-slot:label>
            {{ $t("process.fields.stages") }}
            <strong class="red--text">*</strong>
          </template>
        </v-combobox>
        <h3 v-if="disableSDT" class="text-center pt-5">
          {{ translate(processSelected.sdt_to_show.language_key, true) }}
          <span class="text-center text--secondary">{{
            $t("process.titles.inactive")
          }}</span>
        </h3>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import { mapGetters } from "vuex"
  import LanguajeService from "@/services/LanguajeService"
  import sortList from "@/services/OrderListService"
  import _ from "lodash";

  export default {
    name: "HeaderProcessComponent",

    props: {
      disableSDT: {
        type: Boolean,
        require: true,
      },
      ready: {
        type: Boolean,
        require: true,
      },
    },

    data: () => ({
      max: 40,
      valid: false,
    }),

    watch: {
      valid(value) {
        this.$emit("update:validation-one", value)
      },
    },

    computed: {
      ...mapGetters({
        processTypeEnum: "proccess/processTypeEnum",
        requireAndMaxRules: "general/requireAndMaxRules",
        requireRules: "general/requireRules",
      }),
      storagesTypesByCompany() {
        return _.filter(this.$store.getters['storage_type/storagesTypesByCompany'], { isActive: true });
      },
      processSelected: {
        get() {
          return this.$store.getters["proccess/procces"]
        },
        set(val) {
          this.$store.commit("proccess/SET_PROCESS", val)
        },
      },
    },

    methods: {
      translate(key, uppercase) {
        return LanguajeService.getKey3(key)
      },
      orderDatas(list, order) {
        return sortList.orderListByUppercase(list, order)
      },
      replaceProccessName(event) {
        this.processSelected.proccess_name = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
      },
    },
  }
</script>

<style scoped>
</style>
